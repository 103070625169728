<template>
<div class="container">
  <HeaderUI :is-login="isLogin" :login-url="loginUrl" :extension="extension" v-if="!hideHeader"
            :header-css="headerCss" :is-auth="isAuth" :phone="phone" :name="name" :bind-info="bindInfo"
            @onLogin="toLogin"
            @onUserInfo="onUserInfo"
            @onBindAccount="onBindAccount">
  </HeaderUI>
  <div v-for="item in models" :key="item.id">
    <div v-if="item.children.length>0" class="aui-flex" style="padding-bottom:0;border-bottom: #dddddd solid 1px;">
      <div class="aui-flex-box group-name"><b>{{ item.groupName }}</b></div>
    </div>
      <van-cell label-class="cell-lable-desc" v-for="item1 in item.children" v-bind:key="item1.id" :title="item1.title" is-link @click="toBzRead(item1)">
        <template #icon>
          <div class="van-cell-icon"><img :src="item1.iconFull"/></div>
        </template>
        <template #label>
          <span class="cell-lable-desc">{{item1.remark}}</span>
        </template>
      </van-cell>
      <van-cell v-if="item.groupName =='水费查交'" label-class="cell-lable-desc" title="快捷交费" is-link :to="'../payment/convenient'">
        <template #icon>
          <div class="van-cell-icon" style="width:36px:height:36px"><img :src="require('../../assets/images/search.png')" style="width:36px:height:36px"/></div>
        </template>
        <template #label>
          <span class="cell-lable-desc">通过输入户号交纳水费</span>
        </template>
      </van-cell>
  </div>
  <div>
    <div class="aui-flex" style="padding-bottom:0;border-bottom: #dddddd solid 1px;">
      <div class="aui-flex-box group-name"><b>个人中心</b></div>
    </div>
    <van-cell  is-link @click="toUser('../user/index')">
      <template slot="title">
        <span class="van-cell-text">个人信息</span>
        <van-tag v-if="isLogin && isAuth!=1" color="#ccc" text-color="#ffffff" round size="medium">未认证</van-tag>
        <van-tag v-if="isLogin && isAuth==1"  color="green" text-color="#ffffff" round size="medium">已认证</van-tag>
      </template>
      <template #icon>
        <div class="van-cell-icon"><img :src="require('../../assets/images/user.png')"/></div>
      </template>
      <template #label>
        <span class="cell-lable-desc">修改注册信息/实名认证</span>
      </template>
    </van-cell>
    <van-cell label-class="cell-lable-desc" title="我的户号" is-link @click="toUser('../user/bindAccount?check=0')">
      <!-- 使用 title 插槽来自定义标题 -->
      <template #default>
        <van-tag v-if="isBind"  type="primary" text-color="#ffffff" round size="medium">{{bindWaterCount}}</van-tag>
      </template>
      <template #icon>
        <div class="van-cell-icon"><img :src="require('../../assets/images/user_account_no.png')"/></div>
      </template>
      <template #label>
        <span class="cell-lable-desc">绑定/解绑用水户号</span>
      </template>
    </van-cell>
    <van-cell label-class="cell-lable-desc" title="修改电话" :value="movPhone" is-link @click="toUser('../user/modifyPhone')">
      <template #icon>
        <div class="van-cell-icon"><img :src="require('../../assets/images/phone.png')"/></div>
      </template>
      <template #label>
        <span class="cell-lable-desc">修改注册的手机号码</span>
      </template>
    </van-cell>
    <van-cell label-class="cell-lable-desc" title="修改密码" icon="lock" value="" is-link @click="toUser('../user/modifyPass')">
      <template #icon>
        <div class="van-cell-icon"><img :src="require('../../assets/images/pass.png')"/></div>
      </template>
      <template #label>
        <span class="cell-lable-desc">修改系统登录密码</span>
      </template>
    </van-cell>
  </div>
  <FooterUI v-if="!hideFooter" :tel="tel"></FooterUI>
  <Loading :loding-txt="lodingTxt" :is-show="isShow"/>
</div>
</template>
<script>
import HeaderUI from "../../components/headerUI"
import FooterUI from "../../components/footerUI"
import {setKey, getKey, getCookieKey} from "../../utils/cookie"
import { getModule } from "../../api/index"
import {Dialog} from "vant";
import Loading from "../../components/logding";
import {checkLogin} from "@/api/auth";
export default {
  name: "index",
  components: {HeaderUI , FooterUI,Loading },
  data(){
    return{
      loding: false,
      lodingTxt: '加载中...',
      hideHeader:false,
      hideFooter:false,
      platform: '',
      pcUrl:'',
      headerCss: '',
      userInfo:JSON.parse(getKey(process.env.VUE_APP_USER_INFO) || '{}'),
      isLogin: (getKey(process.env.VUE_APP_H5_IS_LOGIN) || 'false') == 'true' ? true : false,
      isBind: parseInt(getKey(process.env.VUE_APP_USER_IS_BIND) || '0'),
      isAuth: parseInt(getKey(process.env.VUE_APP_USER_IS_AUTH) || '0'),
      models:[],
      extension:{},
      phone: '',
      name: '',
      movPhone:'',
      bindInfo:{},
      loginUrl:'',
      ticket: '',
      bindWaterCount:parseInt(getKey(process.env.VUE_APP_USER_BIND_NUMBER) || '0'),//绑定户号数量
      workCount:0,//工单数量
      tel:'96106'
    }
  },
  created() {
    const authStatus = this.$route.query.authStatus
    const callBackCode = this.$route.query.callBackCode
    if (authStatus){
      if (authStatus=='1' && callBackCode=='3'){
        this.$toast('注册成功')
      }
      if (authStatus=='0' && callBackCode=='3'){
        this.$toast('注册失败')
      }
      if (authStatus=='1' && callBackCode=='6'){
        this.$toast('实名认证成功')
        this.isAuth=1
        setKey(process.env.VUE_APP_USER_IS_AUTH, 1)
      }
      if (authStatus=='0' && callBackCode=='6'){
        this.$toast('实名认证失败')
      }
      this.$router.push('index')
    }
    //console.log(this.$store.getters.userInfo)
    //console.log('platform='+this.$store.getters.platform || '3')
    this.config()
    //获取菜单
    this.getModules()
    this.checkLogin()
      //this.pcUrl = 'http://localhost:8083/kmwater/browserClient/index/h5LoginSync.action?kmwater_h5_token=' + token
  },
  methods: {
    /**
     * 获取首页菜单
     */
    getModules() {
      const that = this
      that.isShow = true
      getModule({}).then(response => {
        ////console.log(response)
        that.isShow = false
        if (response.code == process.env.VUE_APP_SUCCESS_CODE){
          that.models = response.data
          that.extension = response.extension
          if (that.extension){
            that.ticket = that.extension.ticket
            //设置header样式
            that.headerCss = 'background: url("'+that.extension.wxHallHeadBg+'");repeat;background-color: '+that.extension.wxHallBg+';background-size:100vw auto;background-repeat:no-repeat;'
            // eslint-disable-next-line no-prototype-builtins
            if (that.bindInfo && that.bindInfo.hasOwnProperty('accountNo')){
              const accountNo = that.bindInfo.accountNo
              if (accountNo.length == 9)
                that.tel = that.extension.tyTel
              else
                that.tel = that.extension.qyTel
            }
            //console.log(that.headerCss)
          }
        }
      }).catch(() => {
        that.isShow = false
      })
    },
    /**
     * 验证用户登录状态
     */
    confirmLogin(){
      Dialog.confirm({
        title: '温馨提示',
        message: '办理该业务需要登录，请登录后在办理！',
      })
          .then(() => {
            this.toLogin()
          })
          .catch(() => {
            // on cancel
          })
    },
    toLogin(){
      const loginUrl = getKey(process.env.VUE_APP_H5_LOGIN_URL)
      if (loginUrl)
        window.location.href = loginUrl
      else
        this.$router.push('../login/index')
    },
    /**
     * 用户中心
     */
    onUserInfo(){
      if (this.isLogin){
        this.$router.push('../user/index')
      }else{
        this.toLogin()
      }
    },
    /**
     * 户号绑定
     */
    onBindAccount(){},
    /**
     * 跳转绑定界面
     */
    toBind(){},
    toAuth(){
      Dialog.confirm({
        title: '温馨提示',
        message: '办理该业务需要实名认证，您的账号未实名认证，请前往个人信息页进行实名认证！',
      })
          .then(() => {
            this.$router.push('../user/index')
          })
          .catch(() => {
            // on cancel
          })
    },
    /**
     * 跳转到相关业务
     */
    toBzRead(e){
      console.log(e)
      //判断是否需要登录
      if (e.needRegister == 1 && !this.isLogin){
        this.confirmLogin()
        return
      }
      //判断是否需要登录、绑定
      if (e.needBind == 1 && this.isBind == 0){
        //判断是否已登录
        if (!this.isLogin){
          this.confirmLogin()
          return
        }
        //对水费交纳进行特殊处理,如果没有绑定户号，跳转到提示界面
        if (e.title == '水费交纳'){
          this.$router.push('../payment/hint')
          return;
        }
        this.toBind()
        return
      }
      if (e.needAuth == 1 && this.isAuth == 0) {
        //判断是否已登录
        if (!this.isLogin){
          this.confirmLogin()
          return
        }
        if (this.userInfo.account.cardType == '' || this.userInfo.account.cardType.indexOf('身份证') > -1 || this.userInfo.account.cardType.indexOf('驾驶证') > -1 || this.userInfo.account.cardType.indexOf('军官证') > -1) {
          this.toAuth()
          return
        }
      }
      let url = e.curPlatformUrl
      if (url.lastIndexOf('?')>=0)
        url +="&title="+e.title+"&ticket="+this.ticket
      else
        url += "?title="+e.title+"&ticket="+this.ticket
      //console.log("点击菜单："+e.title);
      window.location.href = url
    },
    /**
     * 跳转用户中心相关功能界面
     */
    toUser(url){
      //判断是否已登录
      if (!this.isLogin){
        this.confirmLogin()
        return
      }
      this.$router.push(url)
    },
    /**
     * 获取配置
     */
    config(){
      //重新获取登录状态
      const isLogin = (getKey(process.env.VUE_APP_H5_IS_LOGIN) || 'false') == 'true' ? true : false
      this.isLogin = isLogin
      if (isLogin){
        const userinfo = JSON.parse(getKey(process.env.VUE_APP_USER_INFO) || '{}')
        if (userinfo && userinfo.account){
          //获取手机号码
          if (userinfo.account.phone) {
            this.phone = userinfo.account.phone
            this.phone = this.phone.substr(0,3) + "****" + this.phone.substr(this.phone.length-4)
            this.movPhone = this.phone
          }else{
            this.phone = userinfo.account.name
          }
          //获取选中的绑定的户号
          const bindIndex = parseInt(getKey(process.env.VUE_APP_H5_BIND_CHECK_INDEX) || '0')
          let i = 0
          this.bindWaterCount = userinfo.waterUserList.length
          userinfo.waterUserList.forEach(item => {
            if (i == bindIndex){
              //console.log(item)
              this.bindInfo = item
            }
            i ++;
          })
        }
      }
      //获取平台参数
      const platform = this.$route.query.platform
      const oldPlatform = this.$store.getters.platform
      if (platform && oldPlatform && platform != oldPlatform){
        this.platform = platform
        this.$store.commit('updatePlatform',platform)
        setKey(process.env.VUE_APP_PLATFORM_COOKIE_KEY,platform)
      }
      //获取带参数的token
      const kmwaterToken = this.$route.query.kmwater_h5_token
      const oldToken = getCookieKey(process.env.VUE_APP_TOKEN_KEY)
      if (kmwaterToken && oldToken && kmwaterToken != oldToken){
        setKey(kmwaterToken,process.env.VUE_APP_TOKEN_KEY)
      }
      const hideHeader = this.$route.query.hideHeader
      const hideFooter = this.$route.query.hideFooter
      this.hideHeader = parseInt(hideHeader) == 1 ? true : false
      this.hideFooter = parseInt(hideFooter) == 1 ? true : false
    },/**
     * 验证用户登录状态
     */
    checkLogin() {
      const isLogin = getKey(process.env.VUE_APP_H5_IS_LOGIN) || false
      if (!isLogin) return
      checkLogin().then(response => {
        if (response.code == process.env.VUE_APP_SUCCESS_CODE) {
          const data = response.data
          setKey(process.env.VUE_APP_USER_INFO,JSON.stringify(data))
          setKey(process.env.VUE_APP_H5_IS_LOGIN,true)
          setKey(process.env.VUE_APP_USER_ID,data.id)
          setKey(process.env.VUE_APP_USER_NAME,data.username)
          //是否已绑定
          setKey(process.env.VUE_APP_USER_IS_BIND,data.isBind)
          this.isBind=data.isBind;
          //绑定数量
          setKey(process.env.VUE_APP_USER_BIND_NUMBER,data.waterUserList.length || 0)
          //是否已绑定
          setKey(process.env.VUE_APP_USER_IS_REG,1)
          //是否已实名
          setKey(process.env.VUE_APP_USER_IS_AUTH, data.isAuth)
          this.config()
        } else {
          setKey(process.env.VUE_APP_USER_INFO,"{}")
          setKey(process.env.VUE_APP_H5_IS_LOGIN,false)
          setKey(process.env.VUE_APP_USER_ID,'')
          setKey(process.env.VUE_APP_USER_NAME,'')
          //是否已经注册
          setKey(process.env.VUE_APP_USER_IS_BIND,0)
          //是否已绑定
          setKey(process.env.VUE_APP_USER_IS_REG,0)
          //是否已实名
          setKey(process.env.VUE_APP_USER_IS_AUTH, 0)
          setKey(process.env.VUE_APP_H5_LOGIN_URL,response.data)
          setKey(process.env.VUE_APP_USER_BIND_NUMBER,0)
          this.loginUrl = response.data
          this.config()
        }
      }).catch(() => {
        setKey(process.env.VUE_APP_USER_INFO,"{}")
        setKey(process.env.VUE_APP_H5_IS_LOGIN,false)
        setKey(process.env.VUE_APP_USER_ID,'')
        setKey(process.env.VUE_APP_USER_NAME,'')
        //是否已经注册
        setKey(process.env.VUE_APP_USER_IS_BIND,0)
        //是否已绑定
        setKey(process.env.VUE_APP_USER_IS_REG,0)
        //是否已实名
        setKey(process.env.VUE_APP_USER_IS_AUTH, 0)
        setKey(process.env.VUE_APP_USER_BIND_NUMBER,0)
        this.config()
      })
    }
  }
}
</script>

<style scoped>
  .van-tag--default{
    position:absolute;
    margin-left:12px;
    margin-top:2px;
  }
</style>
