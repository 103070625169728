<template>
  <div class="index-header">
    <div class="water-logo">
      <div class="header-placeholder"></div>
      <div class="welcomephone">{{ phone }} <span style="font-size: 22px;">{{ hoursTip }}！</span></div>
      <div class="welcome"> {{welcome}}</div>
      <div class="welcome"></div>
    </div>
  </div>
  <!--
<div class="index-header" :style="headerCss">
  <div v-if="isLogin">
  <van-row>
    <van-col span="12">
      <van-cell @click="onBindAccount" :icon="require('../assets/images/hh.png')" style="background: transparent;border: 0px;">
        <template #title>
          <span style="color: white;">户号：{{ bindInfo.accountNo }}</span>
          <van-icon :name="require('../assets/images/down.png')" />
        </template>
      </van-cell>
    </van-col>
    <van-col span="12">
      <van-cell :icon="require('../assets/images/set.png')" @click="onUserInfo" style="background: transparent;">-->
         <!-- 使用 title 插槽来自定义标题 --><!--
        <template #title>
          <span class="heade-user-phone">{{ phone }}</span>
          <van-tag v-if="!isAuth" color="#ccc" text-color="#ffffff" round size="medium">未认证</van-tag>
          <van-tag v-if="isAuth"  color="green" text-color="#ffffff" round size="medium">已认证</van-tag>
        </template>
      </van-cell>
    </van-col>
  </van-row>
  </div>
  <div v-if="!isLogin" class="no-login">
    <van-button plain block round type="info" @click="onLogin">登录</van-button>
  </div>
</div>-->
</template>

<script>
export default {
  name: "headerUI",
  props:{
    extension:{},
    headerCss:{},
    isAuth: {},
    phone: {},
    name: {},
    bindInfo:{},
    isLogin:{},
    loginUrl:{}
  },
  data(){
    return {
      welcome:'欢迎使用昆水集团用水服务',
      hoursTip:''
    }
  },
  created() {
    this.getMycount()
  },
  methods:{
    onLogin(){
      this.$emit('onLogin')
    },
    onUserInfo(){
      this.$emit('onUserInfo')
    },
    onBindAccount(){
      this.$emit('onBindAccount')
    },

    getMycount () {
      const that = this
      let date = new Date()
      if (date.getHours() >= 0 && date.getHours() < 12) {

        that.hoursTip = "早上好"

      } else if (date.getHours() >= 12 && date.getHours() < 18) {

        that.hoursTip = "下午好"

      } else {

        that.hoursTip = "晚上好"

      }
    }
  }
}
</script>

<style scoped>
.heade-user-phone{color: white;}
.heade-user-auth-state{display:flex;float: left;width: 100px;text-align: center;line-height: 30px;height: 30px; font-size: 3px;color: white;
  background: green;
  margin-top: 5px;padding: 2px;
  background: 30px;
}
.no-login{
  display: flex; height: 150px;line-height: 150px; padding-top: 50px; padding-left: 30%;padding-right: 30%
}
.water-logo{
  background:url("../assets/images/shuibao.png");background-repeat: no-repeat;
  background-size:95px 95px;
  background-position: 96% 35%;
}
.welcome{width: 100%;padding-left: 20px;font-size: 18px;line-height: 30px;height: 30px;color: #efefef;}
.header-placeholder{width: 100%;height: 20px;}
.welcomephone{width: 100%;padding-left: 20px;font-size: 18px;color: white;line-height: 30px;height: 30px;}
</style>