<template>
  <div class="footer-container">
    <footer style="border-top: 1px solid #ebebeb;height: 44px;background: white;">
      <van-cell :url="'tel://'+tel">
        <template #title>
          <div class="help-tel">
            <span style="font-size: 16px;">请致电客服热线</span>
            <span style="font-size: 22px;">{{ tel }}</span>
            <van-icon :name="require('../assets/images/tel.png')" />
          </div>
        </template>
      </van-cell>
    </footer>
  </div>
</template>
<script>
export default {
  name: "footerUI",
  props:{
    tel:{}
  }
}
</script>